import React, {useState, useEffect, useRef} from 'react'
import Header from 'components/Headers/Header';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Badge,
    CardImg,
    CardImgOverlay,
    TabContent, TabPane, Nav, NavItem, NavLink, 
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form
} from "reactstrap";
import AddTpcCourseModal from 'components/Modal/AddTpcCourseModal';
import Paginate from 'components/Pagination/TpcCoursesPagination';
import TotalCourses from 'components/Widgets/TpcCourses/TotalCourses';
import { fetchPaymentRecord } from 'redux/Payments/PaymentsActions';
import ManageCourseItem from 'components/Courses/ManageCourseItem';
import { connect, useDispatch } from 'react-redux';
import { fetchTpcCourses, deleteTpcCourse, deleteTpcCourseReset, createBundleCourses, createBundleCoursesReset, fetchBundleCourses, saveCoursesOrder, saveCoursesOrderReset} from 'redux/ManageTpcCourses/ManageTpcCoursesActions'
import Swal from 'sweetalert2'
import ManageTpcCoursesLessons from './ManageTpcCoursesLessons';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// DnD Item Types
const ItemType = {
    COURSE: "COURSE",
  };
  
  const DraggableCourse = ({ course, index, moveCourse }) => {
    const ref = useRef(null);
  
    const [{ isDragging }, drag] = useDrag({
      type: ItemType.COURSE,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
  
    const [, drop] = useDrop({
      accept: ItemType.COURSE,
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveCourse(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });
  
    drag(drop(ref));
  
    return (
        <div
            ref={ref}
            style={{
                padding: "15px",
                border: "1px solid #ccc",
                marginBottom: "10px", // Add margin to prevent overlap
                borderRadius: "5px",
                backgroundColor: isDragging ? "#e0e0e0" : "#f9f9f9",
                cursor: "grab",
            }}

            className='w-100'
            >
            <strong>Order {index + 1} : </strong> <span>{course.courseTitle}</span>
        </div>

    );
  };


const ManageTpcCourses = (props) => {
    const dispatch = useDispatch()

    const addZeroes = num => num.toLocaleString("en",{minimumFractionDigits: 2})

    const [tpcCoursesSale,setTpcCoursesSale] = useState(0);
    const [paidUsers,setPaidUsers] = useState([]);
    const [bundleCourses, setBundleCourses] = useState([]);

    useEffect(() => {
        dispatch(fetchPaymentRecord({table: 'tpcCourses'}))
        dispatch(fetchBundleCourses())

    }, []);

    useEffect(() => {
        if(props.fetchBundleCourses.bundlecourses.length){
           setBundleCourses(props.fetchBundleCourses.bundlecourses)
        }
    }, [props.fetchBundleCourses])

    useEffect(() => {
        if(props.fetchPaymentRecord.fetchpaymentrecord.length){
            let purchased = 0
            let users = []
            props.fetchPaymentRecord.fetchpaymentrecord[0].map((p)=>{
                return (
                    users.push(p.userHandle),
                    purchased += p.amount
                ) 
            })
            // console.log(users)
            setPaidUsers(users)
            setTpcCoursesSale(addZeroes(purchased))
        }
    }, [props.fetchPaymentRecord ])
    

    const [currentPage, setCurrentPage] = useState(1);
	const [coursesPerPage] = useState(6);

    const [modalAddTpcCourseModal, setModalAddTpcCourseModal] = useState(false);
    const [tpcCoursesLists, setTpcCoursesLists] = useState([]);

    const [courseLists, setCoursesLists] = useState([]);

    const indexOfLastPost = currentPage * coursesPerPage;
	const indexOfFirstPost = indexOfLastPost - coursesPerPage;
	const currentCourses = tpcCoursesLists.slice(indexOfFirstPost, indexOfLastPost);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const previousPage = () => {
		if (currentPage !== 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const nextPage = () => {
		if (currentPage !== Math.ceil(tpcCoursesLists.length / coursesPerPage)) {
			setCurrentPage(currentPage + 1);
		}
	};

    const [editCourse, setEditCourse] = useState(false);
   
    
    const toggleAddTpcCourseModal = () =>{
        setEditCourse(false)
        setModalAddTpcCourseModal(!modalAddTpcCourseModal);
        
    } 

    const [modalOrderCoursesModal, setModalOrderCoursesModal] = useState(false);

    const toggleOrderCoursesModal = () =>{
        setModalOrderCoursesModal(!modalOrderCoursesModal);
        
    } 
    
    useEffect(() => {
        
        dispatch(fetchTpcCourses({}))

    },[props.createTpcCourse, props.updateTpcCourse, props.deleteTpcCourse], [])
    
    useEffect(() => {
        
        if(props.fetchTpcCourses.tpccourses.length){
            

            setTpcCoursesLists(props.fetchTpcCourses.tpccourses)
            setOrderedCourses(props.fetchTpcCourses.tpccourses)
            setCoursesLists(props.fetchTpcCourses.tpccourses)
        }
        else{
            setTpcCoursesLists([])
            setOrderedCourses([])
            setCoursesLists([])
        }
        

    }, [props.fetchTpcCourses ])
    

    const handleEditDetails = (index) => {
        // console.log(index)
        setModalAddTpcCourseModal(!modalAddTpcCourseModal);
        setEditCourse(index)
        
    }

    const handleDeleteDetails = (tpcCourseId) => {
        
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteTpcCourse({tpcCourseId : tpcCourseId}))
        
            }
        })

    }

    useEffect(() => {
    
        
        if(props.deleteTpcCourse.tpccourses.length && typeof props.deleteTpcCourse.error === "undefined"){
          
          Swal.fire({
              title: 'Success!',
              text: 'Successfully deleted TPC course!',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(deleteTpcCourseReset());
            
          })
          
        }
      
        else if(typeof props.deleteTpcCourse.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to delete TPC course!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(deleteTpcCourseReset());
            
          })    
                
        }
      
    }, [props.deleteTpcCourse])

    const [editLesson, setEditLesson] = useState("")

    // console.log(editLesson)

    const handleEditLesson = (e,courseData) => {
        e.preventDefault()
        setEditLesson(courseData)
        
    } 

    const handleBackToTpcCourses = (e) => {
        e.preventDefault()
        setEditLesson("")
    }

    const [searchCourse, setSearchCourse] = useState("");
    const [searchedCourse, setSearchedCourse] = useState([]);

    const searchCourses = async (value) => {
        let search = await arraySearch(tpcCoursesLists, value);
        setSearchedCourse(search);
        setSearchCourse(value);
    };
    const arraySearch = (array, keyword) => {
        // console.log(array)
        const searchTerm = keyword.toLowerCase();
        return array.filter((value) => {
            return (
                value.courseDescription.toLowerCase().match(new RegExp(searchTerm, "g")) ||
                value.courseTitle.toLowerCase().match(new RegExp(searchTerm, "g")) ||
                value.instructorName.toLowerCase().match(new RegExp(searchTerm, "g"))
            );
        });
    };

      //Modal Behaviour
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

    const createBundleModal = () => {
        setBackdrop(true);
        setModal(true);
    }

    const [selectedCourse, setSelectedCourse] = useState([])
    const updateListOfItems = (e, index) => {
        
        const updatedList = [...courseLists];
        updatedList[index].checked = e.target.checked;
        setSelectedCourse(updatedList);
      
    }

    const [bundlePrice, setBundlePrice] = useState("")
    const [bundleTitle, setBundleTitle] = useState("")

    const handleBundlePriceChange = (event) => {
        setBundlePrice(event.target.value);
    };
    const handleBundleTitleChange = (event) => {
        setBundleTitle(event.target.value);
    };


    const createBundle = () =>{
        let bundleCourseIds = [];
        if(selectedCourse.length){
            selectedCourse.forEach(element => {
                if(element.checked === true){
                    bundleCourseIds.push(element.tpcCourseId)
                }
            });

        }

        let data = {
            price : bundlePrice,
            title: bundleTitle,
            courses: bundleCourseIds,
            userHandle: props.userDetails.handle
        }

        if(bundleCourseIds.length < 2){
            Swal.fire({
                title: "Select courses",
                text: "Select atleast 2 courses",
                type: "error"
            })
        }
        if(!bundleTitle || !bundlePrice){
            Swal.fire({
                title: "Fields Required",
                text: "Title and Price field are required",
                type: "error"
            })
        }
        if(bundleCourseIds.length >= 2 && bundleTitle &&  bundlePrice ){
            setModal(false)
            dispatch(createBundleCourses(data))
        }
        
    }


    useEffect(() => {
        if (props.createBundleCourses.loading) {
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });
        } else {
            if(props.createBundleCourses.bundlecourse.length){
                Swal.fire({
                    title: "Success!",
                    text: "Successfully created!",
                    icon: "success",
                    timer: 3000,
                }).then(() => {
                    dispatch(createBundleCoursesReset());
                    dispatch(fetchBundleCourses())
                    
                });
            }
           
        }
    }, [props.createBundleCourses]);


    // const sampleCourses = [
    //     {
    //       tpcCourseId: "course1",
    //       courseTitle: "Introduction to React",
    //       order: 1,
    //     },
    //     {
    //       tpcCourseId: "course2",
    //       courseTitle: "Advanced React Techniques",
    //       order: 2,
    //     },
    //     {
    //       tpcCourseId: "course3",
    //       courseTitle: "State Management with Redux",
    //       order: 3,
    //     },
    //     {
    //       tpcCourseId: "course4",
    //       courseTitle: "Building APIs with Node.js",
    //       order: 4,
    //     },
    //     {
    //       tpcCourseId: "course5",
    //       courseTitle: "Mastering GraphQL",
    //       order: 5,
    //     },
    //   ];
      
    const [orderedCourses, setOrderedCourses] = useState(tpcCoursesLists);

    // Function to move a course
    const moveCourse = (fromIndex, toIndex) => {
        setOrderedCourses((prev) => {
          const updatedCourses = [...prev];
          const [movedItem] = updatedCourses.splice(fromIndex, 1);
          updatedCourses.splice(toIndex, 0, movedItem);
      
          // Update the order field
          updatedCourses.forEach((course, idx) => (course.order = idx + 1));
          return updatedCourses;
        });
      };
      
      
    // Function to save the order
    const saveOrder = () => {
        const saveOrder = orderedCourses.map(item => ({courseTitle: item.courseTitle, tpcCourseId: item.tpcCourseId, order: item.order }));

        dispatch(saveCoursesOrder(saveOrder));

        Swal.showLoading()
        console.log("Saved Order:", saveOrder);
       
     
    };

   
     useEffect(() => {
        if(props.saveCoursesOrder.coursesorder.length && typeof props.saveCoursesOrder.error === "undefined"){
          
            Swal.fire({
                title: 'Success!',
                text: 'Successfully saved courses order!',
                icon : 'success',
                timer: 3000
                
              }
            ).then((result) => {
                dispatch(fetchTpcCourses({}))
                dispatch(saveCoursesOrderReset());
              
            })
            
        }
        
        else if(typeof props.saveCoursesOrder.error !== "undefined"){
         
            Swal.fire({
                title:'Failed!',
                text:'Failed to save courses order!',
                icon :'error',
                timer: 3000
                
              }
            ).then((result) => {
                dispatch(saveCoursesOrderReset());
              
            })    
                  
        }
     }, [props.saveCoursesOrder])
     
      
      // Function to reset data (for testing purposes)
    //   const resetSampleData = () => setOrderedCourses([...sampleCourses]);

    return (
        <>
          <Header></Header>
        {
            editLesson ? 
            <>
              
                <Container className="mt--7" fluid>
                    <a href="" className="text-warning">
                    <Button className="shadow" color="info" onClick={(e) => handleBackToTpcCourses(e)}><i className="fas fa-arrow-left"></i>&nbsp;&nbsp;Back to TPC Courses</Button></a>
                </Container> 
            </>
            
            : "" 
            
        }
        {
        Object.keys(editLesson).length === 0? <>
            <Container className="mt--7" fluid>
                <TotalCourses  courses={tpcCoursesLists} payments={tpcCoursesSale} students={paidUsers} />
                {/* <Row className="mt-4">
                   
                  
                </Row> */}
                <Row>
                    <Col lg="8 mt-3">
                        <Row>
                            <Col lg="12" md="12" sm="12" >
                                <FormGroup>
                                    <InputGroup className="mb-4">
                                        <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-search" />
                                        </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Search.."
                                            type="text"
                                            value={searchCourse}
                                            onChange={(e) => searchCourses(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12" sm="12">
                                <Button title="Add Course"  className="float-right ml-1 mr-1" color="success" onClick={toggleAddTpcCourseModal} ><i className="fas fa-plus" /> Add Course </Button>
                                <Button title="Order Courses"  className="float-right ml-1 mr-1" color="success" onClick={toggleOrderCoursesModal} ><i className="fas fa-sort-numeric-down" /> Order Courses </Button>
                                {/* <Button title="Add Course" className="float-right rounded-circle d-sm-block d-lg-none d-xs-block d-md-none" color="success" onClick={toggleAddTpcCourseModal} > <i className="fas fa-plus" /> </Button> */}
                            </Col>
                        </Row>
                    
                        {/* {   
                        searchCourse ? 
                                searchedCourse.length ? 
                                <>
                                    <>
                                    {
                                        searchedCourse.map((c, i) => {
                                            return ( <ManageCourseItem key={c.tpcCourseId} item={c} index={c.courseId} handleEditDetailsCallback={handleEditDetails} handlehandleEditCourseLessonsCallback={handleEditLesson} handleDeleteDetailsCallback={handleDeleteDetails} />)
                                        })
                                    }
                                    </>
                                    
                                    <div >
                                        {
                                        searchedCourse.length > coursesPerPage ?
                                            <div className="d-flex justify-content-center mt-3">
                                                <Paginate
                                                    itemPerPage={coursesPerPage}
                                                    totalItems={searchedCourse.length}
                                                    currentPage={currentPage}
                                                    paginate={paginate}
                                                    previousPage={previousPage}
                                                    nextPage={nextPage}
                                                />
                                            </div>
                                        : ""

                                        }
                                        
                                    </div>
                                </>   
                                : ("No Result")  
                            : 
                        
                        
                        !tpcCoursesLists.length ? 
                        
                                <Card className='shadow'>
                                    <CardBody className='text-center'>
                                        <h2 className='mb-0'>No courses listed.</h2>
                                    </CardBody>
                                </Card>
                        
                            :
                            currentCourses.map((c, i) => {
                                return (
                                    <ManageCourseItem key={c.tpcCourseId} item={c} index={c.courseId} handleEditDetailsCallback={handleEditDetails} handlehandleEditCourseLessonsCallback={handleEditLesson} handleDeleteDetailsCallback={handleDeleteDetails}/>
                                )
                            }) 
                        } */}

                    <Row>
                    <Col lg="12" md="12" className='mt-3'>
                    { 
                        searchCourse ? 
                            searchedCourse.length ? 
                            <Row>
                                
                                {
                                    searchedCourse.map((c, i) => {
                                        return (<Col xl="6" lg="6" md="12" sm="12" className="d-flex flex-column flex-wrap"><ManageCourseItem key={c.tpcCourseId} item={c} index={c.courseId} handleEditDetailsCallback={handleEditDetails} handlehandleEditCourseLessonsCallback={handleEditLesson} handleDeleteDetailsCallback={handleDeleteDetails} /></Col>)
                                    })
                                }
                                
                                
                                <Col xl="12" lg="12">
                                    {
                                    searchedCourse.length > coursesPerPage ?
                                        <div className="d-flex justify-content-center mt-3">
                                            <Paginate
                                                itemPerPage={coursesPerPage}
                                                totalItems={searchedCourse.length}
                                                currentPage={currentPage}
                                                paginate={paginate}
                                                previousPage={previousPage}
                                                nextPage={nextPage}
                                            />
                                        </div>
                                    : ""

                                    }
                                    
                                </Col>
                            </Row>

                                
                                
                            : <p className='text-center font-weight-bolder text-dark'>No Result</p>
                        : 
                         <>
                        
                            { !tpcCoursesLists.length ? 
                                <Card className='shadow'>
                                    <CardBody className='text-center'>
                                        <h2 className='mb-0'>No courses listed.</h2>
                                    </CardBody>
                                </Card>
                                :
                                <Row>   
                                    {

                                        currentCourses.map((c, i) => (
                                        
                                            <Col xl="6" lg="6" md="12" sm="12" className="d-flex flex-column flex-wrap"><ManageCourseItem key={c.tpcCourseId} item={c} index={c.courseId} handleEditDetailsCallback={handleEditDetails} handlehandleEditCourseLessonsCallback={handleEditLesson} handleDeleteDetailsCallback={handleDeleteDetails}/></Col>
                                            
                                            
                                        ))
                                    }
                                    <Col xl="12" lg="12">
                                    {
                                        tpcCoursesLists.length > coursesPerPage ?
                                        <div className="d-flex justify-content-center mt-3">
                                            <Paginate
                                                itemPerPage={coursesPerPage}
                                                totalItems={tpcCoursesLists.length}
                                                currentPage={currentPage}
                                                paginate={paginate}
                                                previousPage={previousPage}
                                                nextPage={nextPage}
                                            />
                                        </div>
                                        :""
                                    }
                                    </Col>
                                </Row>
                            } 
                       
                      
                        

                        </>
                        }
                        </Col>
                        </Row>


                    </Col>
                    <Col lg="4">
                        <Card className="shadow-lg rounded mb-2">
                            <CardBody className="">
                                <Row>
                                    <Col lg="12">
                                        <CardTitle tag="h3">
                                            Bundle Courses
                                            <Button title="Add a Bundle" size="sm" className="float-right" color="info"  onClick={createBundleModal}> Create Bundle</Button>
                                        </CardTitle>
                                    </Col>
                                </Row>
                               
                                <div className="mt-3">
                                   {
                                    bundleCourses.length ?
                                    bundleCourses.map((c,i)=>(
                                        <Card className="shadow mb-1" role="button">
                                            <CardBody className="position-relative">
                                                <CardTitle>
                                                    <h5>{i+1}. {c.title}  
                                                        <Badge
                                                            color="success"
                                                            size="sm"
                                                            className="rounded-pill float-right"
                                                            >
                                                            ${addZeroes(Number(c.price))}
                                                        </Badge>
                                                    </h5>
                                                   
                                                </CardTitle>
                                                <Row className="justify-content-center">
                                                   
                                                    {
                                                        c.courseItems.length ? 
                                                            c.courseItems.map((course,i)=>(
                                                               <Card className="col-lg-5 col-md-5 col-sm-5  p-0 m-1" >
                                                                
                                                                    {course.courseThumbnail != "" ? 
                                                                    
                                                                        <CardImg
                                                                            alt="..."
                                                                            src={`https://storage.googleapis.com/pre-dental-tracker.appspot.com/img-tpc-courses/` + course.courseThumbnail+``}
                                                                            
                                                                            top
                                                                            style={{height : "100%", objectFit: "fill", width:"100%"}}
                                                                        />
                                                                        
                                                                        :

                                                                        <CardImg
                                                                            alt="..."
                                                                        
                                                                            src={require("assets/img/brand/no-image.jpg")}
                                                                            top
                                                                             style={{height : "100%", objectFit: "fill", width:"100%"}}
                                                                        />
                                                                    }

                                                                    
                                                                    <small className="text-truncate">{course.courseTitle}</small>
                                                                    

                                                                   
                                                                </Card>
                                                               
                                                               
                                                            ))
                                                        : ""
                                                    }
                                                </Row>
                                                <Badge  
                                                    style={{position: "absolute",zIndex:"9",left:"46%",top:"53%"}}
                                                    color="primary"   
                                                    size="sm"
                                                    className="rounded-pill fill"
                                                    >
                                                    <i className="fa fa-plus"></i>
                                                </Badge>
                                            </CardBody>
                                        </Card>

                                    ))
                                    : 
                                    ""
                                   }
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row >
                    {
                    tpcCoursesLists.length > coursesPerPage
                        ?
                        <Col md="12" className="d-flex justify-content-center mt-3">
                            <Paginate
                                itemPerPage={coursesPerPage}
                                totalItems={tpcCoursesLists.length}
                                currentPage={currentPage}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                            />

                        </Col>
                        :""
                    }
                   
                   
                </Row>
                   
               
            </Container>
            </>
            : <Container className="mt-3" fluid><ManageTpcCoursesLessons courseData={editLesson}></ManageTpcCoursesLessons></Container>}
            <AddTpcCourseModal modalAddTpcCourseModal={modalAddTpcCourseModal} toggleAddTpcCourseModal={toggleAddTpcCourseModal} editCourse={editCourse} coursesData={props.fetchTpcCourses} />
            
            <Modal
                isOpen={modal}
                toggle={modalToggle}
                backdrop={backdrop}
                size="xl"
                style={{width: '100%'}}
         
            >
            <ModalHeader toggle={modalToggle}>
                Create A Bundle Course
            </ModalHeader>
                <ModalBody className=""  >
                    <Row>
                        <Col lg="6">
                            {
                                courseLists.length ? 
                                // console.log(tpcCoursesLists)
                                courseLists.map((course,index)=>(
                                
                                    <Card className="shadow-lg rounded mb-3 position-relative" key={index} >
                                        <FormGroup check style={ { position: 'absolute', zIndex:'999'}}>
                                            <Label check>
                                                <Input style={ { top: 0 }} key={course.tpcCourseId} type="checkbox"  name={"select-"+course.tpcCourseId} id={"select-"+course.tpcCourseId}  onChange={(e) => updateListOfItems(e,index)} />{' '}
                                            </Label>
                                        </FormGroup>
                                     
                                        <CardBody className="p-0 ">
                                            <Row>
                                                <Col lg="6" className="m-0 p-0">
                                                    {course.courseThumbnail != "" ? 
                                                    
                                                        <CardImg
                                                            alt="..."
                                                            src={`https://storage.googleapis.com/pre-dental-tracker.appspot.com/img-tpc-courses/` + course.courseThumbnail+``}
                                                            
                                                            top
                                                            style={{height : "100%", objectFit: "fill", width:"100%"}}
                                                        />
                                                        
                                                        :

                                                        <CardImg
                                                            alt="..."
                                                        
                                                            src={require("assets/img/brand/no-image.jpg")}
                                                            top
                                                            style={{height : "100%", objectFit: "fill", width:"100%"}}
                                                        />
                                                    }
                                                </Col>
                                                <Col lg="6" className=" d-flex flex-column flex-wrap">
                                                    <div className="mb-auto py-2 px-2">
                                                        <h5 role="button" >{course.courseTitle}</h5>
                                                    </div>
                                                    <div className="mb-1 px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="rounded-pill"
                                                            >
                                                            ${addZeroes(Number(course.coursePrice))}
                                                        </Button>
                                                    
                                                        <div className="mt-2 mb-1 ">
                                                            <Badge color={course.visibility == "published" ? "info" : "danger"} className="mr-3">{course.visibility}</Badge>
                                                           
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                ))

                                : ""
                            }

                        </Col>
                        <Col lg="6">

                            <Row className="mb-3">
                                {
                                    selectedCourse.length ?
                                        selectedCourse.map((c,i) =>(
                                            c.checked === true ?
                                            <Col lg="6">
                                                <Card key={c.tpcCourseId}  >
                                                    {c.courseThumbnail != "" ? 
                                                            
                                                        <CardImg
                                                            alt="..."
                                                            src={`https://storage.googleapis.com/pre-dental-tracker.appspot.com/img-tpc-courses/` + c.courseThumbnail+``}
                                                            width="100%"
                                                            top
                                                           
                                                        />
                                                        
                                                        :

                                                        <CardImg
                                                            alt="..."
                                                             width="100%"
                                                            src={require("assets/img/brand/no-image.jpg")}
                                                            top
                                                           
                                                        />
                                                    }
                                                     <CardBody className="p-2">
                                                        <CardTitle tag="h6">{c.courseTitle}</CardTitle>
                                                        <Badge
                                                            color="success"
                                                            size="sm"
                                                            className="rounded-pill"
                                                            >
                                                            ${addZeroes(Number(c.coursePrice))}
                                                        </Badge>
                                                     </CardBody>

                                                </Card>
                                            </Col>
                                            : ""
                                        ))
                                    : ""
                                }
                           
                            </Row>
                            <Row>
                                <Form className="col-md-12">
                                    <FormGroup>
                                        <Label for="bundleprice">Price</Label>
                                        <Input size="sm" value={bundlePrice} type="number" name="bundleprice" id="bundleprice" placeholder="" onChange={handleBundlePriceChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="bundletitle">Title</Label>
                                        <Input size="sm" value={bundleTitle} type="text" name="bundletitle" id="bundletitle" placeholder="" onChange={handleBundleTitleChange}/>
                                    </FormGroup>
                                
                                </Form>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <div className="float-right">
                                        <Button size="" color="secondary" onClick={modalToggle}>Cancel</Button>
                                        <Button size="" color="primary" onClick={createBundle}>Save</Button>{' '}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            

            <Modal className="modal-dialog-centered" size="lg" isOpen={modalOrderCoursesModal}  toggle={toggleOrderCoursesModal}>
                    
                <ModalBody className='modal-body p-0'>
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Order Courses</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                            <h6 className="heading-small text-muted mb-4">
                                Courses
                            </h6>
                         
                            <DndProvider backend={HTML5Backend}>
                                <div>
                                    {orderedCourses.map((course, index) => (
                                    
                                    <DraggableCourse
                                        key={course.tpcCourseId}
                                        course={course}
                                        index={index}
                                        moveCourse={moveCourse}
                                    />
                                    
                                    ))}
                                </div>
                            </DndProvider>
                                    
                        
                            
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"  onClick={toggleOrderCoursesModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={saveOrder}>
                            Save
                    </Button>
                </ModalFooter>
                
            </Modal>
        </>
    )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchTpcCourses : state.fetchTpcCourses,
        createTpcCourse : state.createTpcCourse,
        updateTpcCourse : state.updateTpcCourse,
        deleteTpcCourse : state.deleteTpcCourse,
        fetchPaymentRecord: state.fetchPaymentRecord,
        createBundleCourses: state.createBundleCourses,
        fetchBundleCourses :state.fetchBundleCourses,
        saveCoursesOrder: state.saveCoursesOrder
        
    }
      
}

export default connect(mapStateToProps)(ManageTpcCourses)
