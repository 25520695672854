import React, { useState, useEffect } from "react";
import Header from "components/Headers/Header";
import DataTable from "react-data-table-component";
import {
  Card,
  CardTitle,
  CardText,
  CardLink,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Input,
  CardFooter,
  Button,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
  Spinner,
  CustomInput,
} from "reactstrap";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { connect, useDispatch } from "react-redux";
import {
  createCoachTutorAvailability,
  createCoachTutorAvailabilityReset,
  fetchCoachTutorAvailability,
  deleteCoachTutorAvailability,
  deleteCoachTutorAvailabilityReset,
  fetchHistoryCoachTutorAvailability,
} from "redux/ManageCoachTutorAvailability/ManageCoachTutorAvailabilityActions";
import Swal from "sweetalert2";
import { app, db } from "../../firebase";
import Select from "react-select";
import {
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  addDoc,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
  or,
} from "firebase/firestore";
const CoachSchedule = (props) => {
    const dispatch = useDispatch();
    const [selectedDays, setSelectedDays] = useState([]);
    const [displayDays, setDisplayDays] = useState([]);
    const [repeatDays, setRepeatDays] = useState([]);

    //Modal
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(true);
   
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    ///.....
  };

  useEffect(() => {

    //modal Default Value
    
    setStartTime(convertTimeToLocal(props.item.starttime));
    setEndTime(convertTimeToLocal(props.item.endtime));
    setStartDate(convertToDefaultValue(props.item.startdate));
    setEndDate(convertToDefaultValue(props.item.enddate));
    // console.log(props.item.startdate, convertToDefaultValue(props.item.startdate) ,"fuck")
    //Description
    setDescription(props.item.description)

    //Repeat days
    setDisplayDays(props.item.days)

    
  }, [props.item]);

  useEffect(() => {
    if (props.item.days.length) {
        setSelectedDays(
          props.item.days.map((i) => repeatDays.find((r) => i === r.value))
        );
      }
  }, [props.item.days]);

  const convertToDefaultValue = (v) => {
    
    return moment(v,"MMMM D, YYYY").utcOffset(0, false);
  }

  const convertTimeToLocal = (time) => {
    
    return moment(moment(time).utcOffset(0, true), "HH:mm")
  }


  useEffect(() => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const setdays = [];
    days.forEach((d) => {
      setdays.push({
        value: d,
        label: d,
      });
    });
    setRepeatDays(setdays);
  }, []);


  //Modal Behaviour
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [backdrop, setBackdrop] = useState(true);

  const editCoachSchedule = () => {
    setBackdrop(true);
    setModal(true);
  };

   let twoDaysBefore = moment().add(1, "day");
    let valid = function (current) {
        return current.isAfter(twoDaysBefore);
    };
    const handleStartDateOnchange = (e) => {
        setStartDate(new Date(e.toJSON()));
    }
    const handleEndDateOnchange = (e) => {
        setEndDate(new Date(e.toJSON()));
    }
    const handleStartTimeOnchange = (e) => {
        
        setStartTime(new Date(e.toJSON()));
    }
    const handleEndTimeOnchange = (e) => {
        setEndTime(new Date(e.toJSON()));
    }

    const updateSchedule = async () => {
        const dataDays = [];
        if (selectedDays) {
        selectedDays.map((i) => dataDays.push(i.value));
        }

        const updateDocs = {
            startdate: convertThedate(startDate),
            enddate: convertThedate(endDate),
            starttime: moment.utc(startTime).format(),
            endtime: moment.utc(endTime).format(),
            description: description,
            days: dataDays,
            updatedAt: new Date().toISOString(),
        };

        console.log(updateDocs)

        const docRef = doc(db, "coachAvailableSchedule", props.item.schedid);
        const docSnap = await setDoc(docRef, updateDocs, { merge: true });

        setModal(!modal)
        Swal.fire({
            title: "Success",
            text: "Sucessfully created",
            icon: "success",
            timer: 3000,
        });
        dispatch(fetchCoachTutorAvailability({handle: props.userDetails.handle}));
    
  }
  const convertThedate = (date) => {
    return moment(date,"MMMM D, YYYY h:mm A").utcOffset(0, false).format('ll');
  }

 
  //On Click Delete Schedule
  const deleteCoachSchedule = () => {
  
      Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonColor: '#3085d6',
          confirmButtonColor: '#d33'
      }).then( async (result) => {
          
          if (result.isConfirmed) {
            await deleteDoc(doc(db, "coachAvailableSchedule", props.item.schedid));
            dispatch(fetchCoachTutorAvailability({handle: props.userDetails.handle}));
          }
      }) 
  }




  return (
    <>
      {props.item ? (

        <>
          <button
          onClick={editCoachSchedule}
          className="btn btn-sm btn-primary"
        >Edit</button>

         <button
          onClick={deleteCoachSchedule}
          className="btn btn-sm btn-danger"
        >Delete</button>
        </>
        // <Col lg="4" className="mb-3">
        //   <Card className="p-2 shadow-lg rounded ">
        //     <CardText>
        //         <div className="p-2">
        //             <div className="d-flex ">
        //                 <h5><span className="text-muted">Start Date: </span> {dateFrom} </h5>
        //             </div>
        //             <div className="d-flex  mb-2">
        //                 <h5><span className="text-muted">End Date: </span> {dateTo} </h5>
        //             </div>
        //             <div className="d-flex  mb-4">
        //                 <h5><span className="text-muted">Time: </span> {timeFrom + " - " + timeTo} </h5>
        //             </div>
        //         </div>
        //        <div className="p-2">
        //         <h5>Repeat Days: </h5>
        //         {repeatDays.map((r, i) => {
        //           const clr = displayDays.length
        //             ? displayDays.includes(r.value)
        //               ? "primary"
        //               : "secondary"
        //             : "secondary";
        //           return (
        //             <Badge key={i} color={clr} className="mr-1">
        //               {r.value}
        //             </Badge>
        //           );
        //         })}
        //       </div>
        //     </CardText>
        //     <Button onClick={editCoachSchedule}>Edit</Button>
        //   </Card>
        // </Col>
      ) : (
        ""
      )}
        <Modal
          isOpen={modal}
          toggle={modalToggle}
          backdrop={backdrop}
          size="lg"
          centered
        >
          <ModalHeader toggle={modalToggle}>
            Update Schedule Availability
          </ModalHeader>
          <ModalBody className="pt-1">
            <Row>
                <Col lg="6">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                        Start Date <span className="text-danger"> *</span>
                    </label>
                    <ReactDatetime
                        onChange={(e) => handleStartDateOnchange(e)}
                        isValidDate={valid}
                        value={startDate}
                        timeFormat={false}
                        className="w-100"
                        inputProps={{ placeholder: "Select Date" }}
                    />
                    </FormGroup>
                </Col>
                  <Col lg="6">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                        End Date  <span className="text-danger">*</span>
                    </label>
                    <ReactDatetime
                        onChange={(e) => handleEndDateOnchange(e)}
                        value={endDate}
                        isValidDate={valid}
                        timeFormat={false}
                        className="w-100"
                        inputProps={{ placeholder: "Select Date" }}
                    />
                    </FormGroup>
                </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    Start Time  <span className="text-danger">*</span>
                  </label>
                  <ReactDatetime
                    value={startTime}
                    onChange={(e) => handleStartTimeOnchange(e)}
                    timeConstraints={{
                       minutes: { min: 30, max: 30, step: 31 },
                    }}
                    dateFormat={false}
                    className="w-100"
                    inputProps={{ placeholder: "Select time" }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    End Time <span className="text-danger">*</span>
                  </label>
                  <ReactDatetime
                    value={endTime}
                    onChange={(e) => handleEndTimeOnchange(e)}
                    timeConstraints={{
                       minutes: { min: 30, max: 30, step: 31 },
                    }}
                    dateFormat={false}
                    className="w-100"
                    inputProps={{ placeholder: "Select time" }}
                  />
                </FormGroup>
             
                </Col>
                <Col lg="12">
                    <Label>Select days to repeat the time schedule</Label>
                    <FormGroup>
                        <Select
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            isMulti
                            options={repeatDays}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={selectedDays}
                            onChange={setSelectedDays}
                        />
                    </FormGroup>
                </Col>
                <Col lg="12">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                        Description
                    </label>
                    <Input
                        placeholder="Write a description..."
                        rows="5"
                        type="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    </FormGroup>
                </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={modalToggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={updateSchedule}>Save</Button>
          </ModalFooter>
        </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    createCoachTutorAvailability: state.createCoachTutorAvailability,
    fetchCoachTutorAvailability: state.fetchCoachTutorAvailability,
    fetchHistoryCoachTutorAvailability:
      state.fetchHistoryCoachTutorAvailability,
    deleteCoachTutorAvailability: state.deleteCoachTutorAvailability,
  };
};

export default connect(mapStateToProps)(CoachSchedule);
